<template>
  <b-row class="mx-0">
    <b-col cols="12 white--text text-center"><div class="py-5 px-3">{{ $t("downloadapp") }}</div></b-col>
    <b-col cols="12" sm="6">
      <a class="google-btn blu flex vert py-3 store-btn mb-4" target="_blank" href="https://play.google.com/store/apps/details?id=hu.fitradio.app">
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 408 466.9"
          width="2185"
          height="2500"
        >
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            y1="112.094"
            x2="261.746"
            y2="112.094"
          >
            <stop offset="0" stop-color="#63be6b" />
            <stop offset=".506" stop-color="#5bbc6a" />
            <stop offset="1" stop-color="#4ab96a" />
          </linearGradient>
          <path
            class="st0"
            d="M261.7 142.3L15 1.3C11.9-.5 8-.4 5 1.4c-3.1 1.8-5 5-5 8.6 0 0 .1 13 .2 34.4l179.7 179.7 81.8-81.8z"
          />
          <linearGradient
            id="SVGID_2_"
            gradientUnits="userSpaceOnUse"
            x1=".152"
            y1="223.393"
            x2="179.896"
            y2="223.393"
          >
            <stop offset="0" stop-color="#3ec6f2" />
            <stop offset="1" stop-color="#45afe3" />
          </linearGradient>
          <path
            class="st1"
            d="M.2 44.4C.5 121.6 1.4 309 1.8 402.3L180 224.1.2 44.4z"
          />
          <linearGradient
            id="SVGID_3_"
            gradientUnits="userSpaceOnUse"
            x1="179.896"
            y1="229.464"
            x2="407.976"
            y2="229.464"
          >
            <stop offset="0" stop-color="#faa51a" />
            <stop offset=".387" stop-color="#fab716" />
            <stop offset=".741" stop-color="#fac412" />
            <stop offset="1" stop-color="#fac80f" />
          </linearGradient>
          <path
            class="st2"
            d="M402.9 223l-141.2-80.7-81.9 81.8 92.4 92.4L403 240.3c3.1-1.8 5-5.1 5-8.6 0-3.6-2-6.9-5.1-8.7z"
          />
          <linearGradient
            id="SVGID_4_"
            gradientUnits="userSpaceOnUse"
            x1="1.744"
            y1="345.521"
            x2="272.296"
            y2="345.521"
          >
            <stop offset="0" stop-color="#ec3b50" />
            <stop offset="1" stop-color="#e7515b" />
          </linearGradient>
          <path
            class="st3"
            d="M1.7 402.3c.2 33.3.3 54.6.3 54.6 0 3.6 1.9 6.9 5 8.6 3.1 1.8 6.9 1.8 10 0l255.3-148.9-92.4-92.4L1.7 402.3z"
          />
        </svg>
        <p class="my-0">
          {{$t('getit')}} <br />
          <span class="big-txt">Google Play</span>
        </p>
      </a>
     
    </b-col>
    <b-col cols="12" sm="6">
      <a class="app-btn blu flex vert py-3 store-btn" href="https://itunes.apple.com/hu/app/fitradio/id1462194331" target="_blank">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style="enable-background: new 0 0 512 512"
          xml:space="preserve"
          width="512"
          height="512"
        >
          <g>
            <path
              d="M185.255,512c-76.201-0.439-139.233-155.991-139.233-235.21c0-129.404,97.075-157.734,134.487-157.734   c16.86,0,34.863,6.621,50.742,12.48c11.104,4.087,22.588,8.306,28.975,8.306c3.823,0,12.832-3.589,20.786-6.738   c16.963-6.753,38.071-15.146,62.651-15.146c0.044,0,0.103,0,0.146,0c18.354,0,74.004,4.028,107.461,54.272l7.837,11.777   l-11.279,8.511c-16.113,12.158-45.513,34.336-45.513,78.267c0,52.031,33.296,72.041,49.292,81.665   c7.061,4.248,14.37,8.628,14.37,18.208c0,6.255-49.922,140.566-122.417,140.566c-17.739,0-30.278-5.332-41.338-10.034   c-11.191-4.761-20.845-8.862-36.797-8.862c-8.086,0-18.311,3.823-29.136,7.881C221.496,505.73,204.752,512,185.753,512H185.255z"
            />
            <path
              d="M351.343,0c1.888,68.076-46.797,115.304-95.425,112.342C247.905,58.015,304.54,0,351.343,0z"
            />
          </g>
        </svg>
        <p class="my-0">
          {{$t('available')}}<br />
          <span class="big-txt">App Store</span>
        </p>
      </a>
    </b-col>
  </b-row>
</template>

<script type="text/babel">
import Vue from "vue";

export default Vue.extend({
  data() {
    return {};
  },

  methods: {},
});
</script>
<style lang="scss">
@import "@/styles/colors.scss";
@font-face {
  font-family: "fontfutura";
  src: url("https://fonts.googleapis.com/css?family=Open+Sans") format("ttf");
  font-weight: normal;
  font-style: normal;
}
.store-btn {
  max-width: 400px;
  width: 100%;
  border-radius: 5px;
border: 1px solid #b5b5b5;
margin:auto;
  color: #fff;
  text-align: left;
  text-decoration: none;
  font-family: "Lucida Grande", sans-serif;
  font-size: 10px;
  text-transform: uppercase;
  &.google-btn{
    svg{
      width: 18%;
    }
   
  }
  &.blu {
    background-color: #101010;
    transition: background-color 0.25s linear;
    &:hover {
      background-color: #454545;
      color: #ffffff;
    text-decoration: underline;
    text-decoration: none;
    }
  }
  svg {
    position: relative;
    /* top: -8px; */
    width: 20%;
    margin-right: 5%;
    height: 20%;
    fill: white;
  }
  p{
    font-size: 130%;
    .big-txt {
    font-size: 180%;
    text-transform: capitalize;
  }
  }
  
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.st0{fill:url(#SVGID_1_)}
.st1{fill:url(#SVGID_2_)}
.st2{fill:url(#SVGID_3_)}
.st3{fill:url(#SVGID_4_)}
</style>
