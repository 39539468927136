<template>
  <div class="bg-img relative playlist pt-3" style="" v-if="$store.state.auth.user.type==='GYM'">
    <div
      v-if="
        typeof selectedPlaylistToList === 'object' &&
        Object.keys(selectedPlaylistToList).length > 0
      "
    >
      <div>
        <b-aspect :aspect="1" class="responsive m-auto aspect-img mt-0 mt-lg-4" style="">
          <div class="w-100 h-100 img-place">
            <b-row class="h-100">
              <b-col
                :cols="
                  selectedPlaylistToList.image.length < 2
                    ? 12
                    : getColImg(image, i)
                "
                v-for="(image, i) in selectedPlaylistToList.image"
                :key="image"
                :style="{
                  background: 'url(' + image + ')',
                }"
                class="imgs"
              >
              </b-col>
            </b-row>
          </div>
        </b-aspect>
        <h2 class="text-center mt-4 mb-5 uppercase white--text underliner relative">
          <span class="relative">{{ selectedPlaylistToList.name }}</span>
        </h2>
        <div class="text-center relative mb-4" style="">
          <b-btn
            pill size="lg"
            variant="primary"
            @click="playPlaylist()"
            :disabled="
              typeof selectedPlaylistToList !== 'object' ||
              Object.keys(selectedPlaylistToList).length < 1 ||
              selectedPlaylistToList.tracks.length < 1
            "
          >
            {{ $t('play') }}
          </b-btn>
        </div>
      </div>
      <div class="tracklist mt-5">
        <div
          v-for="(song, i) in selectedPlaylistToList.tracks"
          :key="i"
          :class="[
            'lighter--text',
            'lighter--fill',
            'py-4',
            'playlist-track',
            i === playlistIndex && selectedPlaylistToList.id === playlist.id
              ? 'active-track'
              : '',
          ]"
        >
          <div class="d-flex" style="justify-content: space-between">
            <div class="pl-3">{{ song.title }}</div>
            <svg
              class="pointer"
              @click="selectSongToMenu(song)"
              id="Capa_1"
              enable-background="new 0 0 515.555 515.555"
              height="20"
              viewBox="0 0 515.555 515.555"
              width="50"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m496.679 212.208c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138 65.971-25.167 91.138 0"
              />
              <path
                d="m303.347 212.208c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138 65.971-25.167 91.138 0"
              />
              <path
                d="m110.014 212.208c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138 65.971-25.167 91.138 0"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="trackMenu"
      id="track-menu"
      :title="
        selectedSongToMenu.hasOwnProperty('title')
          ? selectedSongToMenu.title
          : ''
      "
      centered
      hide-footer
    >
      <div class="menu-cont" v-if="!moveTrack && !sortChange">
        <div @click="deleteTrack()" class="py-3 list-item px-3 pointer">
          {{ $t('delete') }}
        </div>
        <div
          @click="selectPlaylistForMove()"
          class="py-3 list-item px-3 pointer"
        >
          {{ $t('moveto_playlist') }}
        </div>
        <div @click="sortChange = true" class="py-3 list-item px-3 pointer">
          {{ $t('modify') }}
        </div>
      </div>
      <div v-if="moveTrack">
        <div
          @click="moveTrackToPlaylist(playlist)"
          class="py-3 list-item px-3 pointer"
          v-for="(playlist, i) in playlists"
          :key="i"
        >
          {{ playlist.name }}
        </div>
      </div>
      <div v-if="sortChange">
        <b-form-group class="px-3 pt-4" v-if="selectedPlaylistToList.type !=='FAVORITE'">
          <b-form-input
            :placeholder="$t('playlist_label')"
            v-model="changeName"
            size="lg"
            class="form-control rounded"
            autofocus=""
            type="text"
          >
          </b-form-input>
        </b-form-group>
        <draggable v-model="draggableTracks" @start="drag = true"
                   @end="drag = false" v-bind="dragOptions" handle=".dragicon">
          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <div
              v-for="(element, i) in draggableTracks"
              class="py-3 list-item px-3 pointer drag"
              :key="i"
            >
              <div>{{ element.title }}</div>
              <div class="pl-2">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;width:15px"
                     class="primary--fill dragicon" xml:space="preserve">

	<g>
		<g>
			<rect x="0" y="277.333" width="384" height="42.667"/>
      <rect x="0" y="170.667" width="384" height="42.667"/>
      <rect x="0" y="64" width="384" height="42.667"/>
		</g>
	</g>

</svg>
              </div>
            </div>
          </transition-group>
        </draggable>
        <div class="text-center py-3">
          <b-btn variant="primary" pill @click="sendPlaylistToServer()">{{
              $t('send')
            }}
          </b-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script type="text/babel">
import Vue from 'vue';
import { apiClient } from '@/util/resources';
import draggable from 'vuedraggable';

export default Vue.extend({
  data() {
    return {
      playlistId: null,
      addPlaylistDialog: false,
      selectedSongToMenu: {},
      selectedPlaylistToList: {},
      trackMenu: false,
      moveTrack: false,
      sortChange: false,
      draggableTracks: [],
      changeName: '',
      drag: false
    };
  },
  components: {
    draggable,
  },
  name: 'playlist',
  watch: {
    selectedPlaylistToList: {
      handler(val) {
        this._debug('selectedplaylisttolist changed', val);
        this.draggableTracks = [...val.tracks];
        //this.getPlaylist();
      },
      deep: true,
    },
    song: {
      handler(val) {
        this._debug('song changed in playlist', val);
      },
      deep: true,
    },
    playlists: {
      handler(val) {
        this._debug('playlists changed in playlist', val);
        if (
          typeof this.selectedPlaylistToList === 'object' &&
          Object.keys(this.selectedPlaylistToList).length > 0
        ) {
          this.getPlaylist(this.selectedPlaylistToList);
        }
      },
      deep: true,
    },

    playPlaylist(nv, ov) {
      if (nv) {
        this.$store.commit('channels/channel', {});
        this.$store.commit('playlists/setPlaylist', this.selectedPlaylistToList);
        this.$store.commit('playlists/setPlaylistIndex', 0);
      }
    },
    playlistMenu(val) {
      if (!val) {
        this.sortChange = false;
      }
    },
    trackMenu(val) {
      if (!val) {
        this.sortChange = false;
      }
    },
    sortchange(val) {
      //this.draggableTracks=val? [...this.selectedPlaylistToList.tracks] : [];
    },
  },
  mounted() {},
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    playlists: {
      get() {
        return this.$store.state.playlists.playlists;
      },
      set(value) {
        this.$store.commit('playlists/setPlaylists', value);
      },
    },
    playlist: {
      get() {
        return this.$store.state.playlists.playlist;
      },
      set(value) {
        this.$store.commit('playlists/setPlaylist', value);
      },
    },
    playlistIndex() {
      return this.$store.state.playlists.playlistIndex;
    },
  },

  created() {
    this.playlistId = this.$router.currentRoute.params.id;
    this.getPlaylist();
  },
  methods: {
    getColImg(image, i) {
      let l = this.selectedPlaylistToList.image.length;
      if (l === 3) {
        if (i + 1 === 3) return 12;
        return 6;
      }
      return 6;
    },
    playPlaylist() {
      //this.playlistId=selectedPlaylistToList.id;
      this._debug('playplaylist()');
      this.playlist = this.selectedPlaylistToList;
      //this.$store.commit('channels/setPlayChannel', false);
      this.$store.commit('playlists/setPlayPlaylist', true);
    },
    getPlaylists() {
      const this_ = this;
      apiClient
        .get('api/playlists', {})
        .then((response) => {
          this._debug('playlists', response);

          if (Array.isArray(response.data)) {
            this_.$store.commit('playlists/setPlaylists', response.data);
          }
        })
        .catch((error) => {
          this.$root.$bvToast.toast($t(error.message), {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    sendPlaylistToServer() {
      const this_ = this;
      const body = {
        id: this_.selectedPlaylistToList.id,
        name: this_.changeName.length > 0 ? this_.changeName : this_.selectedPlaylistToList.name,
        audioIds: this_.draggableTracks.map((m) => m.id),
      };
      apiClient

        .patch(`/api/playlists`, body)
        .then((response) => {
          this_.trackMenu = false;
          this._debug('changed playlist', response);
          this_.getPlaylists();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    deleteTrack(
      playlistId = this.selectedPlaylistToList.id,
      songId = this.selectedSongToMenu.id
    ) {
      const this_ = this;
      this.trackMenu = false;

      apiClient

        .delete(`/api/playlists/${playlistId}/audios/${songId}`, {})
        .then((response) => {
          this_._debug('delete track', response);
          this_.getPlaylists();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    updatePlaylist() {
      if (this.selectedPlaylistToList.id === this.playlist.id) {
        this.playlist = this.selectedPlaylistToList;
      }
    },
    deletePlaylist() {
      const this_ = this;
      this.playlistMenu = false;
      apiClient

        .delete(`/api/playlists/${this_.selectedPlaylistToMenu.id}`, {})
        .then((response) => {
          this._debug('delete playlist', response);
          this_.getPlaylists();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    selectPlaylistForMove() {
      this.moveTrack = true;
    },
    moveTrackToPlaylist(playlist) {
      const this_ = this;
      this.trackMenu = false;
      this.moveTrack = false;
      apiClient
        .post(`/api/playlists/audios`, {
          audioId: this_.selectedSongToMenu.id,
          id: playlist.id,
        })
        .then((response) => {
          this._debug('song added', response);
          //this_.$store.commit("channels/setChannels", response.data);
          if (response.data) {
            this_.deleteTrack(
              this_.selectedPlaylistToList.id,
              this_.selectedSongToMenu.id
            );
            // this_.getPlaylists();
          }
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    getPlaylist(playlist, update = true) {
      const this_ = this;
      apiClient
        .get(`/api/playlists/${this_.playlistId}`, {})
        .then((response) => {
          this._debug('playlist', response);
          this_.selectedPlaylistToList = response.data;
          if (update) {
            this.updatePlaylist();
          }
          //this_.$store.commit('playlists/setPlaylist', response.data);
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    createPlaylist() {
      const this_ = this;
      apiClient
        .post(`/api/playlists`, { name: this_.new_playlist })
        .then((response) => {
          this._debug('create playlist', response);
          this_.getPlaylists();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    selectSongToMenu(song) {
      this.selectedSongToMenu = song;
      this.trackMenu = true;
    },
    selectPlaylistToMenu(playlist) {
      this.selectedPlaylistToMenu = playlist;
      this.playlistMenu = true;
    },
  },
});
</script>
<style lang="scss">
@import "@/styles/colors.scss";

.playlist {
  padding-top: 80px;
  margin-top: 44px !important;
}

.playlist-row {
  border-bottom: 1px solid black;
  margin-top: 2vw;
  padding-bottom: 2vw;
}

div.playlist-track:first-of-type {
  /*padding-top: 50px !important;*/
}

.active-track {
  background: #94c11f26;
  background: linear-gradient(45deg, red, #f2622a);
  position: relative;
  color: white !important;
  font-weight: 500;
  fill: white;

  svg * {
    fill: white;
  }
}

.list-item {
  border-top: 1px solid black;
}

div.list-item:not(.playlist-track):first-of-type {
  border-top: 0;
}

#track-menu___BV_modal_body_,
#playlist-menu___BV_modal_body_ {
  padding: 0;
}

.aspect-img {
  width: 10vw;

  .img-place {
    .imgs {
      display: flex;
      background-size: cover !important;
      background-position: center !important;
    }
  }
}

@media(max-width: 991px) {
  .aspect-img {
    width: 40vw;
  }
  .playlist {
    padding: 30px 0 40px 0px !important;
    margin-top: 0px !important;
    min-height: 100% !important;
  }
}
</style>
