<template>
  <div class="bg-img relative pt-3 playlists">
    <div v-if="showPlaylist">
      <b-modal
        id="modal-playlist"
        hide-footer
        scrollable
        centered
        v-model="addPlaylistDialog"
        :title="$t('add_playlist')"
      >
        <validation-observer v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(createPlaylist)" class="mt-4">
            <b-form-group>
              <validation-provider
                name="add_playlist"
                rules="required|min:3"
                v-slot="{ errors }"
              >
                <b-form-input
                  :placeholder="$t('add_playlist_label')"
                  v-model="new_playlist"
                  size="lg"
                  class="form-control"
                  autofocus=""
                  type="text"
                >
                </b-form-input>
                <small v-show="errors.length > 0" class="text-danger is-danger">
                  <i class="fa fa-warning"></i> {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
            <div class="text-right">
              <b-button
                size="md"
                @click="$bvModal.hide('modal-playlist')"
                class="mr-2"
              >
                {{ $t('no') }}
              </b-button>
              <b-button type="submit" variant="primary" size="md">
                {{ $t('send') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- <h1 class="uppercase pt-5 ml-4 relative primary--text">
        {{ $t("playlist") }}
      </h1>-->

      <div class="relative mt-lg-3 mt-0">
        <b-btn
          pill
          variant="primary"
          class="ml-4 mt-4 mb-4"
          @click="addPlaylistDialog = true"
        >
          {{ $t('add_playlist') }}
        </b-btn>
        <b-row class="pointer">
          <b-col
            class="my-4"
            cols="6"
            md="4"
            lg="3"
            v-for="(playlist, i) in playlists"
            :key="i"
            @click="
            $router.push({ name: 'playlist', params: { id: playlist.id } })
          "
          >
            <div>
              <b-aspect :aspect="1" class="responsive m-auto" style="width: 70%">
                <div
                  class="w-100 h-100 img-place"
                  :style="{ background: 'url(' + playlist.image[0] + ')' }"
                ></div>
              </b-aspect>
            </div>
            <div class="text-center" style="">
              <div class="mt-3">
                <div class="bold uppercase lighter--text">
                  <b>{{ playlist.name }}</b>
                </div>
                <div class="lighter--text">
                  {{ playlist.count }} {{ $t('tracks') }}
                </div>
              </div>
            </div>
            <div class="text-center mb-3 mt-1">
              <svg
                class="pointer lighter--fill"
                @click.prevent.stop="selectPlaylistToMenu(playlist)"
                enable-background="new 0 0 515.555 515.555"
                height="20"
                viewBox="0 0 515.555 515.555"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m496.679 212.208c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138 65.971-25.167 91.138 0"
                />
                <path
                  d="m303.347 212.208c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138 65.971-25.167 91.138 0"
                />
                <path
                  d="m110.014 212.208c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138 65.971-25.167 91.138 0"
                />
              </svg>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-modal
        class=""
        v-model="playlistMenu"
        id="playlist-menu"
        :title="
        selectedPlaylistToMenu.hasOwnProperty('name')
          ? selectedPlaylistToMenu.name
          : ''
      "
        centered
        hide-footer
      >
        <div class="menu-cont" v-if="!sortChange">
          <div @click="deletePlaylist()" class="py-3 list-item px-3 pointer">
            {{ $t('delete') }}
          </div>
          <div
            @click="getPlaylist(selectedPlaylistToMenu), (sortChange = true)"
            class="py-3 list-item px-3 pointer"
          >
            {{ $t('modify') }}
          </div>
        </div>
        <div v-if="sortChange && Array.isArray(selectedPlaylistToList.tracks)">
          <b-form-group
            class="px-3 pt-4"
            v-if="selectedPlaylistToList.type !== 'FAVORITE'"
          >
            <b-form-input
              :placeholder="selectedPlaylistToList.name"
              v-model="changeName"
              size="lg"
              class="form-control rounded"
              autofocus=""
              type="text"
            >
            </b-form-input>
          </b-form-group>
          <draggable
            v-model="draggableTracks"
            @start="drag = true"
            @end="drag = false"
            v-bind="dragOptions"
            handle=".dragicon"
          >
            <transition-group
              type="transition"
              :name="!drag ? 'flip-list' : null"
            >
              <div
                v-for="(element, i) in draggableTracks"
                class="py-3 list-item px-3 pointer drag"
                :key="i"
              >
                <div>{{ element.title }}</div>
                <div class="pl-2">
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 384 384"
                    style="enable-background: new 0 0 384 384; width: 15px"
                    class="lighter--fill dragicon"
                    xml:space="preserve"
                  >
                  <g>
                    <g>
                      <rect x="0" y="277.333" width="384" height="42.667"/>
                      <rect x="0" y="170.667" width="384" height="42.667"/>
                      <rect x="0" y="64" width="384" height="42.667"/>
                    </g>
                  </g>
                </svg>
                </div>
              </div>
            </transition-group>
          </draggable>
          <div class="text-center py-4">
            <b-btn variant="primary" pill @click="sendPlaylistToServer()">{{
                $t('send')
              }}
            </b-btn>
          </div>
        </div>
      </b-modal>
    </div>
    <div v-else>
      <buy/>
    </div>
  </div>
</template>

<script type="text/babel">
import Vue from 'vue';
import { apiClient } from '@/util/resources';
import buy from '@/components/buy';
import draggable from 'vuedraggable';

export default Vue.extend({
  data() {
    return {
      playlistId: null,
      addPlaylistDialog: false,
      new_playlist: '',
      selectedSongToMenu: {},
      selectedPlaylistToMenu: {},
      selectedPlaylistToList: {},
      playlistMenu: false,
      moveTrack: false,
      sortChange: false,
      draggableTracks: [],
      changeName: '',
      drag: false,
      showPlaylist: false
    };
  },
  components: {
    draggable,
    buy
  },
  name: 'playlists',
  watch: {
    selectedPlaylistToList: {
      handler(val) {
        this._debug('selectedplaylisttolist changed', val);
        this.draggableTracks = [...val.tracks];
        //this.getPlaylist();
      },
      deep: true,
    },
    song: {
      handler(val) {
        this._debug('song changed in playlist', val);
      },
      deep: true,
    },
    playlists: {
      handler(val) {
        this._debug('playlists changed in playlist', val);
        if (
          typeof this.selectedPlaylistToList === 'object' &&
          Object.keys(this.selectedPlaylistToList).length > 0
        ) {
          this.getPlaylist(this.selectedPlaylistToList);
        }
      },
      deep: true,
    },
    playPlaylist(nv, ov) {
      if (nv) {
        this.$store.commit('channels/channel', {});
      }
    },
    playlistMenu(val) {
      if (!val) {
        this.sortChange = false;
      }
    },
    trackMenu(val) {
      if (!val) {
        this.sortChange = false;
      }
    },
    sortchange(val) {
      //this.draggableTracks=val? [...this.selectedPlaylistToList.tracks] : [];
    },
  },
  mounted() {
    // this.getPlaylists();
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    playlists: {
      get() {
        return this.$store.state.playlists.playlists;
      },
      set(value) {
        this.$store.commit('playlists/setPlaylists', value);
      },
    },
    playlist: {
      get() {
        return this.$store.state.playlists.playlist;
      },
      set(value) {
        this.$store.commit('playlists/setPlaylist', value);
      },
    },
    playlistIndex() {
      return this.$store.state.playlists.playlistIndex;
    },
  },

  created() {
    this.$store.commit('player/setShow', false);
  },
  methods: {
    playPlaylist() {
      //this.playlistId=selectedPlaylistToList.id;
      this._debug('playplaylist()');
      this.playlist = this.selectedPlaylistToList;
      //this.$store.commit('channels/setPlayChannel', false);
      this.$store.commit('playlists/setPlayPlaylist', true);
    },
    getPlaylists() {
      const this_ = this;
      apiClient
        .get('api/playlists', {})
        .then((response) => {
          this._debug('playlists', response);

          if (Array.isArray(response.data)) {
            this_.$store.commit('playlists/setPlaylists', response.data);
          }
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    sendPlaylistToServer() {
      const this_ = this;
      const body = {
        id: this_.selectedPlaylistToMenu.id,
        name:
          this_.changeName.length > 0
            ? this_.changeName
            : this_.selectedPlaylistToMenu.name,
        audioIds: this_.draggableTracks.map((m) => m.id),
      };
      apiClient

        .patch(`/api/playlists`, body)
        .then((response) => {
          this_.playlistMenu = false;
          this._debug('changed playlist', response);
          this_.getPlaylists();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    deleteTrack(
      playlistId = this.selectedPlaylistToList.id,
      songId = this.selectedSongToMenu.id
    ) {
      const this_ = this;
      this.trackMenu = false;

      apiClient

        .delete(`/api/playlists/${playlistId}/audios/${songId}`, {})
        .then((response) => {
          this_._debug('delete track', response);
          this_.getPlaylists();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    updatePlaylist() {
      if (this.selectedPlaylistToList.id === this.playlist.id) {
        this.playlist = this.selectedPlaylistToList;
      }
    },
    deletePlaylist() {
      const this_ = this;
      this.playlistMenu = false;
      apiClient

        .delete(`/api/playlists/${this_.selectedPlaylistToMenu.id}`, {})
        .then((response) => {
          this._debug('delete playlist', response);
          this_.getPlaylists();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    selectPlaylistForMove() {
      this.moveTrack = true;
    },
    moveTrackToPlaylist(playlist) {
      const this_ = this;
      this.trackMenu = false;
      this.moveTrack = false;
      apiClient
        .post(`/api/playlists/audios`, {
          audioId: this_.selectedSongToMenu.id,
          id: playlist.id,
        })
        .then((response) => {
          this._debug('song added', response);
          //this_.$store.commit("channels/setChannels", response.data);
          if (response.data) {
            this_.deleteTrack(
              this_.selectedPlaylistToList.id,
              this_.selectedSongToMenu.id
            );
            // this_.getPlaylists();
          }
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    getPlaylist(playlist, update = true) {
      const this_ = this;
      apiClient
        .get(`/api/playlists/${playlist.id}`, {})
        .then((response) => {
          this._debug('playlist', response);
          this_.selectedPlaylistToList = response.data;
          if (update) {
            this.updatePlaylist();
          }
          //this_.$store.commit('playlists/setPlaylist', response.data);
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    createPlaylist() {
      const this_ = this;
      apiClient
        .post(`/api/playlists`, { name: this_.new_playlist })
        .then((response) => {
          this._debug('create playlist', response);
          this_.addPlaylistDialog = false;
          this_.getPlaylists();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },

    selectPlaylistToMenu(playlist) {
      this.selectedPlaylistToMenu = playlist;
      this.playlistMenu = true;
    },
  },
});
</script>
<style lang="scss">
@import "@/styles/colors.scss";

.playlists {
  padding-top: 80px;
  margin-top: 44px !important;

}

.playlist-row {
  border-bottom: 1px solid black;
  margin-top: 2vw;
  padding-bottom: 2vw;
}

.list-item {
  border-top: 1px solid black;
}

div.list-item:not(.playlist-track):first-of-type {
  border-top: 0;
}

@media (max-width: 991px) {
  .playlists {
    padding: 30px 0 40px 0 !important;
    margin-top: 0px !important;
    min-height: 100% !important;
  }
}
</style>
